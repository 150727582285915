import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/default.css'
import * as components from './components'
import VueEditor from './plug/Editor/VueEditor'
import Quill from 'quill'
window.Quill = Quill

const Vue = createApp(App)

for (const key in components) {
	const component = components[key]
	Vue.component(key, component)
}
Vue.component('vue-editor', VueEditor)
Vue.use(store)
	.use(router)
	.mount('#app')
