<template>
	<router-view />
</template>
<style>
#app {
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
@media only screen and (max-width: 1240px) {
  #app{
    flex: 0 0 1240px;
  }
}
</style>
