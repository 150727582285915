<template>
	<r-nav-top />
</template>
<script>
	import RNavTop from './RNavTop'
	export default {
		components: {
			RNavTop,
		},
	}
</script>
<style>
	.nav {
		z-index: 99;
		background-color: var(--navbgc);
		left: 0;
		top: 0;
	}
	#nav-left {
		width: var(--navw);
		height: calc(100% - var(--navh));
		display: flex;
		flex-direction: column;
		top: var(--navh);
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	}
	#nav-top {
		left: 0;
		width: 100%;
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
	}
</style>
