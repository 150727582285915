const daynames = [
	'Pazar',
	'Pazartesi',
	'Salı',
	'Çarşamba',
	'Perşembe',
	'Cuma',
	'Cumartesi',
]
const dayshortnames = ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cts']
const monthnames = [
	{ name: 'Ocak', val: 0 },
	{ name: 'Şubat', val: 1 },
	{ name: 'Mart', val: 2 },
	{ name: 'Nisan', val: 3 },
	{ name: 'Mayıs', val: 4 },
	{ name: 'Haziran', val: 5 },
	{ name: 'Temmuz', val: 6 },
	{ name: 'Ağustos', val: 7 },
	{ name: 'Eylül', val: 8 },
	{ name: 'Ekim', val: 9 },
	{ name: 'Kasım', val: 10 },
	{ name: 'Aralık', val: 11 },
]

const mxGetValue = (arr, srch, equalto, rtrn) => {
	var s = arr.filter(o => {
		return o[srch] == equalto
	})[0]
	if (s) return s[rtrn]
	else return ''
}

const trToLower = s => {
	if (s === null) return ''
	if (s) {
		s = s.toString()
		var letters = { İ: 'i', I: 'ı', Ş: 'ş', Ğ: 'ğ', Ü: 'ü', Ö: 'ö', Ç: 'ç' }
		s = s.replace(/(([İIŞĞÜÇÖ]))/g, function(letter) {
			return letters[letter]
		})
		return s.toLowerCase()
	}
}
const ConvertToPathChar = s => {
	if (s === null) return ''
	if (s) {
		s = s.toString()
		var letters = {
			İ: 'i',
			I: 'i',
			Ş: 's',
			Ğ: 'g',
			Ü: 'u',
			Ö: 'o',
			Ç: 'c',
			ı: 'i',
			ş: 's',
			ğ: 'g',
			ü: 'u',
			ö: 'o',
			ç: 'c',
		}
		s = s.replace(/(([İIŞĞÜÇÖışğüçö]))/g, function(letter) {
			return letters[letter]
		})
		return s.toLowerCase()
	}
}

const trToUpper = s => {
	if (s === null) return ''
	s = s.toString()
	var letters = { i: 'İ', ş: 'Ş', ğ: 'Ğ', ü: 'Ü', ö: 'Ö', ç: 'Ç', ı: 'I' }
	if (s == null || s == '') {
		return ''
	} else {
		s = s.replace(/(([iışğüçö]))/g, function(letter) {
			return letters[letter]
		})
		return s.toUpperCase()
	}
}
const makeUrl = s => {
	return ConvertToPathChar(s).replace(/ /g, '_')
}
const mxDate = (f, d, s, st) => {
	let rd = ''
	let sp = s || '-'
	let spt = st || ' '
	let dt = d ? new Date(d) : new Date()
	let fm = f || 'yy-mm-dd'
	let fma = fm.split('-')

	for (let i = 0; i < fma.length; i++) {
		if (fma[i] == 'y')
			rd += dt
				.getFullYear()
				.toString()
				.slice(-2)
		if (fma[i] == 'yy') rd += dt.getFullYear()
		if (fma[i] == 'm') rd += dt.getMonth() + 1
		if (fma[i] == 'mm') rd += (dt.getMonth() + 1).toString().padStart(2, '0')
		if (fma[i] == 'mmm') rd += monthnames[dt.getMonth()].name.slice(0, 3)
		if (fma[i] == 'mmmm') rd += monthnames[dt.getMonth()].name
		if (fma[i] == 'd') rd += dt.getDate()
		if (fma[i] == 'dd')
			rd += dt
				.getDate()
				.toString()
				.padStart(2, '0')
		if (fma[i] == 'ddd' || fma[i] == 'dddd') {
			if (i > 0) {
				let lc = rd.slice(-1)
				if (lc == sp) rd = rd.slice(0, rd.length - 1)
				rd += ' '
			}
			if (fma[i] == 'ddd') rd += dayshortnames[dt.getDay()]
			if (fma[i] == 'dddd') rd += daynames[dt.getDay()]
			if (i == 0) rd += ' '
		}
		if (fma[i] == 't' || fma[i] == 'tt' || fma[i] == 'ttt') {
			if (i > 0) {
				let lc = rd.slice(-1)
				if (lc == sp) rd = rd.slice(0, rd.length - 1)
				rd += spt
			}
			if (fma[i] == 't')
				rd +=
					dt
						.getHours()
						.toString()
						.padStart(2, '0') +
					':' +
					dt
						.getMinutes()
						.toString()
						.padStart(2, '0')
			if (fma[i] == 'tt')
				rd +=
					dt
						.getHours()
						.toString()
						.padStart(2, '0') +
					':' +
					dt
						.getMinutes()
						.toString()
						.padStart(2, '0') +
					':' +
					dt
						.getSeconds()
						.toString()
						.padStart(2, '0')
			if (fma[i] == 'ttt')
				rd +=
					dt
						.getHours()
						.toString()
						.padStart(2, '0') +
					':' +
					dt
						.getMinutes()
						.toString()
						.padStart(2, '0') +
					':' +
					dt
						.getSeconds()
						.toString()
						.padStart(2, '0') +
					'.' +
					dt.getMilliseconds()
			if (i == 0) rd += spt
		}
		if (
			i != fma.length - 1 &&
			fma[i] != 'ddd' &&
			fma[i] != 'dddd' &&
			fma[i] != 't' &&
			fma[i] != 'tt' &&
			fma[i] != 'ttt'
		)
			rd += sp
	}
	return rd
}

export { mxGetValue, trToLower, trToUpper, mxDate, makeUrl }
