import {reactive} from 'vue'
export const gstore = reactive({
	style: {
		datalist: {
			bg_color: '#fff',
			header_bg_color: '#f5f5f5',
			border_color: '#ccc',
			padding: '8px',
			border_rad: '4px',
		},
	},
})
