<template>
	<div
		:id="'r-select-id-' + rnd"
		:class="[
			'r-select-C',
			disabled ? 'disabled' : '',
			isopened ? 'active' : '',
		]"
		:style="[
			pos === 'add' ? (opt ? opt.addstyle : '') : '',
			width ? 'width:' + width : '',
		]"
	>
		<label class="r-select-label">
			<span class="label">{{ label }}</span>
		</label>
		<div class="r-select-activator" @click="openList" v-if="!isopened">
			<span :style="{ color: actcolor }">{{ selectedtxt }}</span>
		</div>
		<div class="r-select-inp" v-if="isopened">
			<r-input :class="['nomp-inp', 'inp-rnd-' + rnd]" v-model="q" :rnd="rnd" />
		</div>
		<div
			:class="[
				'r-select-items-C',
				'select-pop-rnd' + rnd,
				outpage ? 'open-top' : '',
			]"
			v-if="isopened"
		>
			<div v-if="list.length === 0" class="r-select-item-C">
				Sonuç Bulunamadı
			</div>
			<div
				:class="['r-select-item-C', lineSelected(itm)]"
				v-for="(itm, i) in list"
				:key="i"
			>
				<r-checkbox
					v-if="selectable"
					:label="txt ? itm[txt] : itm"
					v-model="selectedVals"
					:val="val ? itm[val] : itm"
					@change="updateVal($event, itm, dataneed)"
				/>
				<template v-else>
					<div
						class="r-select-item-inner-C"
						@click="updateVal($event, itm, dataneed)"
					>
						<slot name="itemtxt" :data="itm" :id="i">
							{{ txt ? itm[txt] : itm }}
						</slot>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
	import { computed, ref, watch } from 'vue'
	import { gstore } from '../globalstore'
	import { trToUpper } from '@/mix/global'
	export default {
		props: {
			label: String,
			modelValue: [Number, String, Boolean],
			opt: Object,
			txt: String,
			val: [Number, String],
			data: {
				type: Array,
				default: new Array(),
			},
			dataneed: [Number, String, Array, Object, Boolean],
			pos: String,
			selectable: {
				type: Boolean,
				default: false,
			},
			selected: {
				type: Array,
				default: new Array(),
			},
			disabled: Boolean,
			width: String,
			actcolor: String,
		},
		setup(props, { emit }) {
			const isopened = ref(false)
			const q = ref('')
			const rnd = ref(parseInt(Math.random() * 1000000000))
			const openTimeout = ref(null)
			const outpage = ref(false)
			const selectedVals = ref(props.selected)

			const selectedtxt = computed(() => {
				if (props.selectable) {
					var stri = ''
					selectedVals.value.forEach((itm, i) => {
						if (props.txt && props.val) {
							props.data.forEach((v, j) => {
								if (itm === v[props.val])
									stri +=
										v[props.txt] +
										(j === selectedVals.value.length - 1 ? '' : ', ')
							})
						} else {
							stri += itm + (i === selectedVals.value.length - 1 ? '' : ', ')
						}
					})
					return stri === '' ? 'Lütfen Seçiniz' : stri
				} else {
					if (props.data) {
						let a = props.data.filter(v => {
							if (props.val) return props.modelValue === v[props.val]
							else return props.modelValue === v
						})[0]
						return a ? (props.txt ? a[props.txt] : a) : 'Lütfen Seçiniz'
					} else {
						return 'Sonuç bulunamadı'
					}
				}
			})

			const list = computed(() => {
				if (props.data) {
					return props.data.filter(v => {
						if (props.txt)
							return trToUpper(v[props.txt]).includes(trToUpper(q.value))
						else return trToUpper(v).includes(trToUpper(q.value))
					})
				} else {
					return []
				}
			})

			const closeItems = e => {
				let p = e.path || (e.composedPath && e.composedPath())
				if (p) {
					p.pop()
					var open = false
					p.forEach(pv => {
						if (pv.id !== '' && pv.id !== undefined && pv.id.includes(rnd.value))
							open = true
					})
				}

				if (!open) {
					isopened.value = false
					clearTimeout(openTimeout.value)
					document.removeEventListener('click', closeItems)
				}
			}

			const openList = e => {
				if (props.disabled) return
				e.stopPropagation()
				q.value = ''

				isopened.value = !isopened.value
				if (isopened.value) {
					setTimeout(() => {
						let el = document.querySelector('.select-pop-rnd' + rnd.value)
						let rc = el.getBoundingClientRect()

						if (window.innerHeight - rc.top < 100) {
							el.style.top = 'auto'
							el.style.bottom = '100%'
							el.style.borderRadius = '8px 8px 0 0'
							el.style.maxHeight = rc.top - 32 + 'px'
						} else {
							el.style.maxHeight = window.innerHeight - rc.top - 32 + 'px'
						}
					}, 5)
					gstore.curr = rnd.value
					document.addEventListener('click', closeItems)
					setTimeout(() => {
						document.querySelector('#r-inp-' + rnd.value).focus()
					}, 20)
				} else {
					clearTimeout(openTimeout.value)
					document.removeEventListener('click', closeItems)
				}
			}

			const updateVal = (e, d, n) => {
				e.stopPropagation()
				emit('change', d, e, n)
				if (!props.selectable) {
					isopened.value = false
					clearTimeout(openTimeout.value)
					document.removeEventListener('click', closeItems)
				} else {
					emit('update:selected', selectedVals.value)
				}

				if (props.val) emit('update:modelValue', d[props.val])
				else emit('update:modelValue', d)
			}
			const lineSelected = d => {
				if (props.val) {
					return d[props.val] === props.modelValue ? 'selected' : ''
				} else {
					return selectedVals.value.indexOf(d.toString()) >= 0 ? 'selected' : ''
				}
			}
			watch(
				() => props.data,
				() => {
					selectedVals.value = []
				}
			)
			watch(
				() => gstore.curr,
				() => {
					if (rnd.value !== gstore.curr) isopened.value = false
				}
			)
			return {
				updateVal,
				selectedtxt,
				isopened,
				openList,
				q,
				rnd,
				list,
				outpage,
				selectedVals,
				lineSelected,
				gstore,
			}
		},
	}
</script>
<style>
	.r-select-C {
		display: flex;
		position: relative;
		border-radius: 6px;
		width: 100%;
		margin: 6px;
		flex-direction: column;
	}
	.r-select-C.active {
		z-index: 19;
	}
	.r-select-C .r-select-activator {
		color: #000;
		display: flex;
		padding: 8px 10px;
		border: 1px solid #ccc;
		border-top: 0;
		border-radius: 6px;
		width: 100%;
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
	.r-select-C .r-select-activator:hover {
		-webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
		box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
	}
	.r-select-C .r-select-label {
		position: absolute;
		top: -8px;
		font-size: 12px;
		padding-left: 13px;
		display: flex;
		width: 100%;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		z-index: 9;
	}
	.r-select-C .r-select-label:after {
		content: '';
		border-top: 1px solid #ccc;
		width: 100%;
		top: -6px;
		position: relative;
		right: 5px;
		margin-left: 11px;
		align-self: flex-end;
	}
	.r-select-C.disabled .r-select-activator {
		border-color: #e0d9d9;
		color: #ababab;
	}
	.r-select-C.disabled .r-select-label:after {
		border-color: #e0d9d9;
	}
	.r-select-C .r-select-label span {
		flex-shrink: 0;
		color: rgb(0, 56, 160);
	}
	.r-select-items-C {
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 0 0 8px 8px;
		position: absolute;
		top: calc(100% - 9px);
		background-color: #fff;
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
		overflow-y: auto;
		z-index: 9;
	}
	.r-select-items-C.open-top {
		top: auto;
		bottom: 100%;
		border-radius: 8px 8px 0 0;
		z-index: 9;
	}
	.r-select-item-C {
		cursor: pointer;
		display: flex;
		text-align: left;
	}
	.r-select-item-inner-C {
		width: 100%;
		padding: 12px;
		cursor: pointer;
		display: flex;
		text-align: left;
	}
	.r-select-item-C:not(:last-child) {
		border-bottom: 1px solid #ececec;
	}
	.r-select-item-C:hover,
	.r-select-item-C.selected {
		background-color: #f5f5f5;
	}
	.nomp-inp.r-inp-C {
		margin: 0;
	}
</style>
