<template>
	<teleport to="body">
		<div class="r-panel-back-C" v-if="isactive" @click="closePanel(true)">
			<div
				:class="['r-panel-C', pos, classes]"
				:style="['width:' + width || '', 'height:' + height || '', alignin]"
				@click="disableClick"
			>
				<div class="r-panel-title-C" v-if="title || slots.title">
					<slot name="title">
						<strong>{{ title }}</strong>
					</slot>
					<div class="r-panel-title-btn-C">
						<slot name="titlebuttons"></slot>
					</div>
				</div>

				<div
					:class="['r-panel-close-btn', closepos]"
					v-if="closebtn"
					@click="closePanel(false)"
				></div>
				<div :class="['r-panel-content-C', classcontent]" v-if="havecontent">
					<slot></slot>
				</div>
			</div>
		</div>
	</teleport>
</template>
<script>
	import { computed, ref } from 'vue'
	import { gstore } from '../globalstore'
	export default {
		props: {
			title: String,
			content: String,
			canceltxt: {
				type: String,
				default: 'Vazgeç',
			},
			isactive: {
				type: Boolean,
				default: false,
			},
			width: [Number, String],
			height: Number,
			havecontent: {
				type: Boolean,
				default: true,
			},
			closebtn: {
				type: Boolean,
				default: true,
			},
			align: String,
			pos: String,
			classes: {
				type: String,
				default: '',
			},
			classcontent: {
				type: String,
				default: '',
			},
			closepos: {
				type: String,
				default: 'out-right',
			},
			closeout: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit, slots }) {
			const rnd = ref(parseInt(Math.random() * 10000000))
			const disableClick = e => {
				if (props.closeout) {
					e.stopPropagation()
				} else {
					gstore.curr = rnd.value
				}
			}
			const closePanel = b => {
				if (b && props.closeout) emit('update:isactive', false)
				else if (!b) emit('update:isactive', false)
			}
			const alignin = computed(() => {
				if (props.align) {
					let a = props.align.split(' ')
					if (a.length === 1) {
						a[1] = 'alignItems:' + a[0]
					} else if (a.length === 2) {
						a[1] = 'alignItems:' + a[1]
					}
					a[0] = 'justifyContent:' + a[0]
					return a
				}
			})
			return { disableClick, closePanel, alignin, slots }
		},
	}
</script>
<style>
	.r-panel-back-C {
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 16px;
		z-index: 999;
		top: 0;
		left: 0;
	}
	.r-panel-C {
		background-color: #fff;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
		display: flex;
		flex-direction: column;
		width: 40%;
		top: 0;
		right: 0;
		position: absolute;
		height: 100%;
	}
	.r-panel-C.right {
		right: 0;
	}
	.r-panel-C.center {
		right: auto;
	}
	.r-panel-C.left {
		left: 0;
	}
	.r-panel-title-C {
		padding: 10px;
		width: 100%;
		border-bottom: 1px solid rgb(233, 233, 233);
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #fff;
	}
	.r-panel-content-C {
		padding: 20px;
		width: 100%;
		overflow-y: auto;
		height: 100%;
	}
	.r-panel-buttons-C {
		padding: 10px 16px;
		width: 100%;
		border-top: 1px solid rgb(233, 233, 233);
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.r-panel-close-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		position: absolute;
		right: 16px;
		top: 4px;
		cursor: pointer;
	}
	.r-panel-close-btn.out-right {
		left: 100%;
		background-color: #fff;
		top: 0;
		border-radius: 0 4px 4px 0;
	}
	.r-panel-close-btn:before,
	.r-panel-close-btn:after {
		content: '';
		position: absolute;
		width: 24px;
		border-top: 2px solid #ccc;
		transform: rotate(45deg);
	}
	.r-panel-close-btn:after {
		transform: rotate(-45deg);
	}
	.r-panel-close-btn:hover:before,
	.r-panel-close-btn:hover:after {
		border-color: rgb(7, 60, 139);
	}
</style>
