<template>
	<div class="r-textarea-C" :style="pos === 'add' ? opt.addstyle : ''">
		<label>
			<span class="label">{{ label }}</span>
		</label>
		<textarea
			:rows="rows || '4'"
			:value="modelValue"
			@input="updateVal"
			@focus="gstore.curr = rnd"
		></textarea>
		<span v-if="isValid !== ''" class="r-textarea-valid-txt">
			{{ isValid }}
		</span>
	</div>
</template>
<script>
	import { ref, computed } from 'vue'
	import { gstore } from '../globalstore'
	export default {
		props: {
			label: String,
			modelValue: [Number, String],
			opt: Object,
			pos: String,
			rows: String,
			valid: Boolean,
		},
		setup(props, { emit }) {
			const rnd = ref(parseInt(Math.random() * 10000000))
			const lVal = ref(props.modelValue)
			const isValid = computed(() => {
				if (props.opt && props.opt.valid) {
					if (typeof props.opt.valid === 'function') {
						if (
							lVal.value === '' ||
							lVal.value === undefined ||
							!lVal.value.toString().replace(/\s/g, '').length
						) {
							emit('update:valid', false)
							return 'Bu alan boş bırakılamaz'
						} else {
							emit('update:valid', props.opt.valid(lVal.value))
							return props.opt.valid(lVal.value)
						}
					} else if (props.opt.valid === 'required') {
						if (
							lVal.value === '' ||
							lVal.value === undefined ||
							!lVal.value.toString().replace(/\s/g, '').length
						) {
							emit('update:valid', false)
							return 'Bu alan boş bırakılamaz'
						} else {
							emit('update:valid', true)
							return ''
						}
					}
				} else {
					emit('update:valid', true)
					return ''
				}
			})

			const updateVal = e => {
				lVal.value = e.target.value
				emit('change', e.target.value)
				emit('update:modelValue', e.target.value)
			}
			return { updateVal, rnd, gstore, isValid }
		},
	}
</script>
<style>
	.r-textarea-C {
		display: flex;
		position: relative;
		border-radius: 6px;
		width: 100%;
		margin: 6px;
		flex-direction: column;
	}
	.r-textarea-C textarea {
		padding: 8px 10px;
		border: 1px solid #ccc;
		border-top: 0;
		border-radius: 6px;
		width: 100%;
	}
	.r-textarea-C textarea:focus {
		outline: none;
		border: 1px solid rgb(105, 105, 105);
		border-top: 0;
	}
	.r-textarea-C label {
		position: absolute;
		top: -8px;
		font-size: 12px;
		padding-left: 13px;
		display: flex;
		width: 100%;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
	}
	.r-textarea-C label:after {
		content: '';
		border-top: 1px solid #ccc;
		width: 100%;
		top: -7px;
		position: relative;
		right: 5px;
		margin-left: 11px;
		align-self: flex-end;
	}
	.r-textarea-C label span {
		flex-shrink: 0;
		color: rgb(0, 56, 160);
	}
	.r-textarea-valid-txt {
		padding: 5px;
		color: tomato;
	}
</style>
