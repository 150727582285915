<template>
	<template v-for="(link, i) in links" :key="i">
		<div class="r-link-C" v-if="showMe(link)">
			<div class="r-link-main-C">
				<router-link
					:to="link.route"
					:class="[route.meta.id === link.id ? 'selected' : '']"
				>
					<r-icon :svg="link.svg" size="32px" />
					<span v-if="!link.sub" class="r-link-txt">{{ link.txt }}</span>
				</router-link>
				<div class="r-link-sub-C" v-if="link.sub">
					<span class="r-link-sub-title">{{ link.txt }}</span>
					<template v-for="(sub, j) in link.sub" :key="'sub' + j">
						<router-link :to="sub.route">
							<span class="r-link-sub">{{ sub.txt }}</span>
						</router-link>
					</template>
				</div>
			</div>
		</div>
	</template>
</template>
<script>
	import { useRoute } from 'vue-router'
	import { useStore } from 'vuex'
	export default {
		props: {
			links: Array,
		},
		setup() {
			const route = useRoute()
			const store = useStore()
			const showMe = d => {
				if (d.id) return store.state.userauth.some(r => d.id.includes(r))
				else return true
			}
			return {
				route,
				showMe,
			}
		},
	}
</script>
<style>
	.r-link-C {
		display: flex;
	}
	.r-link-C a {
		display: flex;
		background-color: #fff;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 0 8px 8px 8px;
	}
	.r-link-main-C {
		position: relative;
	}
	.r-link-main-C > a:hover,
	.r-link-main-C > a.selected {
		background-color: rgb(0, 56, 160);
		color: #fff;
	}

	.r-link-C a:hover img {
		fill: #fff;
		color: #fff;
	}
	.r-link-txt {
	}
	.r-link-C a:hover .r-link-txt {
		display: flex;
	}
	.r-link-sub-C {
		display: none;
		flex-direction: column;
		background-color: #fff;
		position: absolute;
		left: 100%;
		top: 0;
		min-width: 200px;
		-webkit-box-shadow: 3px 5px 10px -3px rgba(0, 0, 0, 0.3);
		box-shadow: 3px 5px 10px -3px rgba(0, 0, 0, 0.3);
		border-left: 2px solid #ccc;
	}
	.r-link-main-C:hover .r-link-sub-C {
		display: flex;
	}
	.r-link-sub-C a {
		width: 100%;
		align-items: center;
		display: flex;
		border-bottom: 1px solid rgb(236, 236, 236);
	}
	.r-link-sub-C a:hover {
		border-right: 3px solid rgb(0, 56, 160);
		background-color: rgb(248, 248, 248);
	}
	.r-link-sub-title {
		align-items: center;
		display: flex;
		width: 100%;
		padding: 8px 16px;
		background-color: rgb(233, 233, 233);
		border-bottom: 1px solid #ccc;
	}
	.r-link-sub {
		width: 100%;
		align-items: center;
		display: flex;
		padding: 12px 16px;
	}
</style>
