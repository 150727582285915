<template>
	<div :class="['r-flx', flx ? 'r-flx-' + flx : 'r-flx-12']" :style="[alignin]">
		<slot></slot>
	</div>
</template>
<script>
	import { computed } from 'vue'
	export default {
		props: {
			flx: String,
			align: String,
		},
		setup(props) {
			const alignin = computed(() => {
				if (props.align) {
					let a = props.align.split(' ')
					if (a.length === 1) {
						a[1] = 'alignItems:' + a[0]
						a[2] = 'alignContent:' + a[0]
					} else if (a.length === 2) {
						a[1] = 'alignItems:' + a[1]
					}
					a[0] = 'justifyContent:' + a[0]
					return a
				}
			})
			return { alignin }
		},
	}
</script>
<style>
	.r-flx {
		display: flex;
		flex-wrap: wrap;
		flex: 1 1 auto;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 8px 0;
		position: relative;
	}
	.r-flx-1 {
		width: 8.3333333%;
		flex: 0 8.3333333%;
	}
	.r-flx-2 {
		width: 16.6666667%;
		flex: 0 16.6666667%;
	}
	.r-flx-3 {
		width: 25%;
		flex: 0 25%;
	}
	.r-flx-4 {
		width: 33.3333333%;
		flex: 0 33.3333333%;
	}
	.r-flx-5 {
		width: 41.6666667%;
		flex: 0 41.6666667%;
	}
	.r-flx-6 {
		width: 50%;
		flex: 0 50%;
	}
	.r-flx-7 {
		width: 58.3333333%;
		flex: 0 58.3333333%;
	}
	.r-flx-8 {
		width: 66.6666667%;
		flex: 0 66.6666667%;
	}
	.r-flx-9 {
		width: 75%;
		flex: 0 75%;
	}
	.r-flx-10 {
		width: 83.3333333%;
		flex: 0 83.3333333%;
	}
	.r-flx-11 {
		width: 91.6666667%;
		flex: 0 91.6666667%;
	}
	.r-flx-12 {
		width: 100%;
	}

	.r-flx-al-center {
		justify-content: center;
		align-items: center;
	}
	.r-flx-nom {
		margin: 0;
	}
	.r-flx-nop {
		padding: 0;
	}
	@media only screen and (max-width: 1400px) {
		.r-flx-dw-80 {
			width: 80% !important;
			flex: 1 80% !important;
		}
		.r-flx-tw-100 {
			width: 100% !important;
			flex: 1 100% !important;
		}
	}
	@media only screen and (max-width: 1024px) {
	}
</style>
