<template>
	<i :class="['r-ico-C', awesome ? 'awesome' : '']">
		<slot>
			<img :src="ico" :width="size" :height="size" v-if="ico" />
			<object :data="svg" type="image/svg+xml" v-if="svg"></object>
		</slot>
	</i>
</template>
<script>
	export default {
		props: {
			awesome: String,
			ico: String,
			size: String,
			svg: String,
		},
	}
</script>
<style>
	.r-ico-C {
		width: 60px;
		height: 60px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.r-ico-C object {
		fill: rgb(0, 56, 160);
		width: 32px;
		height: 32px;
		pointer-events: none;
	}
</style>
