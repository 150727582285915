const QuillHtmlEditButtonOptions = {
	// Flags
	debug: Boolean, // default:  false
	syntax: Boolean, // default:  false
	// Overlay
	closeOnClickOverlay: Boolean, // default:  true
	prependSelector: String, // default:  null
	// Labels
	buttonHTML: String, // default:  "&lt;&gt;"
	buttonTitle: String, // default:  "Show HTML source"
	msg: String, // default:  'Edit HTML here, when you click "OK" the quill editor\'s contents will be replaced'
	okText: String, // default:  "Ok"
	cancelText: String, // default:  "Cancel"
	// Quill Modules (for the HTML editor)
	editorModules: {
		// Any modules here will be declared in HTML quill editor instance
	},
}
export default QuillHtmlEditButtonOptions
