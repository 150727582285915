<template>
	<button
		:class="['r-btn-C flx', btnSize]"
		@click="disableClick"
		:style="{ backgroundColor: bgcolor || '' }"
	>
		<slot name="button">
			<h5>
				<slot>{{ title }}</slot>
			</h5>
		</slot>
	</button>
</template>
<script>
	import { computed } from 'vue'
	export default {
		props: {
			title: String,
			bgcolor: String,
			size: {
				type: String,
				default: 'small',
			},
		},
		setup(props) {
			const disableClick = () => {
				//e.stopPropagation()
			}
			const btnSize = computed(() => {
				if (props.size === 'small') return ''
				else return props.size
			})
			return { disableClick, btnSize }
		},
	}
</script>
<style>
	.r-btn-C {
		display: flex;
		padding: 6px;
		background-color: rgb(0, 56, 160);
		border-radius: 6px;
		cursor: pointer;
		color: #fff;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		margin: 2px;
		border: none;
		font-weight: 400;
	}
	.r-btn-C.medium h5 {
		font-size: 13px;
	}
	.r-btn-C.large h5 {
		font-size: 14px;
		padding: 10px 16px;
	}
	.r-btn-C:disabled,
	.r-btn-C:disabled:hover {
		background-color: rgb(226, 226, 226) !important;
	}
	.r-btn-C:focus {
		outline: none;
	}
	.r-btn-C:hover {
		background-color: rgb(233, 85, 0) !important;
	}
</style>
