<template>
	<div
		class="r-inp-C"
		:style="[
			pos === 'add' ? (opt ? opt.addstyle : '') : '',
			width ? 'width:' + width : '',
		]"
	>
		<label v-if="label">
			<span class="label">{{ label }}</span>
		</label>
		<input
			:type="type || 'text'"
			:value="modelValue"
			@input="updateVal"
			@click="disableClick"
			:disabled="disabled"
			@focus="gstore.curr = inrnd"
			:id="'r-inp-' + inrnd"
		/>
		<span v-if="isValid !== ''" class="r-input-valid-txt">{{ isValid }}</span>
	</div>
</template>
<script>
	import { computed, ref } from 'vue'
	import { gstore } from '../globalstore'
	export default {
		props: {
			label: String,
			modelValue: [Number, String],
			opt: Object,
			pos: String,
			width: String,
			type: String,
			disabled: {
				type: Boolean,
				default: false,
			},
			rnd: Number,
			valid: Boolean,
		},
		setup(props, { emit }) {
			const lVal = ref(props.modelValue)
			const inrnd = computed(() => {
				if (props.rnd) return props.rnd
				else return parseInt(Math.random() * 1000000000)
			})
			const isValid = computed(() => {
				if (props.opt && props.opt.valid) {
					if (typeof props.opt.valid === 'function') {
						if (
							lVal.value === '' ||
							lVal.value === undefined ||
							!lVal.value.toString().replace(/\s/g, '').length
						) {
							emit('update:valid', false)
							return 'Bu alan boş bırakılamaz'
						} else {
							emit('update:valid', props.opt.valid(lVal.value))
							return props.opt.valid(lVal.value)
						}
					} else if (props.opt.valid === 'required') {
						if (
							lVal.value === '' ||
							lVal.value === undefined ||
							!lVal.value.toString().replace(/\s/g, '').length
						) {
							emit('update:valid', false)
							return 'Bu alan boş bırakılamaz'
						} else {
							emit('update:valid', true)
							return ''
						}
					}
				} else {
					emit('update:valid', true)
					return ''
				}
			})

			const updateVal = e => {
				lVal.value = e.target.value
				emit('update:modelValue', e.target.value)
			}
			const disableClick = e => {
				e.stopPropagation()
			}
			return { updateVal, disableClick, gstore, isValid, inrnd }
		},
	}
</script>
<style>
	.r-inp-C {
		display: flex;
		position: relative;
		border-radius: 6px;
		width: 100%;
		margin: 6px;
		flex-direction: column;
	}
	.r-inp-C input {
		padding: 8px 10px;
		border: 1px solid #ccc;
		border-top: 0;
		border-radius: 6px;
		width: 100%;
	}
	.r-inp-C input:focus {
		outline: none;
		border: 1px solid rgb(105, 105, 105);
		border-top: 0;
	}
	.r-inp-C label {
		position: absolute;
		top: -8px;
		font-size: 12px;
		padding-left: 13px;
		display: flex;
		width: 100%;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
	}
	.r-inp-C label:after {
		content: '';
		border-top: 1px solid #ccc;
		width: 100%;
		top: -7px;
		position: relative;
		right: 5px;
		margin-left: 11px;
		align-self: flex-end;
	}
	.r-inp-C label span {
		flex-shrink: 0;
		color: rgb(0, 56, 160);
	}
	.r-input-valid-txt {
		padding: 5px;
		color: tomato;
	}
</style>
