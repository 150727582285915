<template>
	<r-nav />
	<div
		id="content-C"
		:class="[store.state.isScrolled ? 'fixed-nav-content' : '']"
	>
		<div
			class="silhouutte-C"
			v-if="store.state.supers.indexOf(store.state.userrole) < 0"
		>
			<div
				class="sihouette-user-info"
				v-for="i in 80"
				:key="i"
				:style="{
					transform:
						'rotate(' + parseInt(Math.floor(Math.random() * 10) - 30) + 'deg)',
				}"
			>
				<span>{{ store.state.userid }}</span>
				<span>{{ store.state.username }}</span>
			</div>
		</div>
		<router-view />
	</div>
</template>
<script>
	import { useStore } from 'vuex'
	import { onBeforeMount } from 'vue'
	export default {
		setup() {
			const store = useStore()
			const scrollListen = () => {
				store.state.scrollY = window.scrollY
				store.state.isScrolled = window.scrollY > 100 ? true : false
			}
			onBeforeMount(async () => {
				await store.dispatch('authControl')
				window.addEventListener('scroll', scrollListen)
			})
			return {
				store,
			}
		},
	}
</script>
<style>
	#content-C.fixed-nav-content {
		padding-top: 150px;
	}
	.silhouutte-C {
		display: flex;
		position: fixed;
		width: 100%;
		height: 100%;
		overflow: hidden;
		left: 0;
		top: 0;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		z-index: 90;
		pointer-events: none;
		opacity: 0.07;
	}
	.sihouette-user-info span {
		display: flex;
		font-size: 24px;
		font-weight: 800;
		padding: 18px;
	}
</style>