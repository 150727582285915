<template>
	<div class="quillWrapper" :style="{ width: width }">
		<slot name="toolbar"></slot>
		<div
			:id="id + rnd"
			:ref="'quillContainer' + rnd"
			:style="{ height: height, width: width }"
		></div>
		<input
			v-if="useCustomImageHandler"
			id="file-upload"
			ref="fileInput"
			type="file"
			accept="image/*"
			style="display: none"
			@change="emitImageInfo($event)"
		/>
	</div>
</template>

<script>
	import Quill from 'quill'
	import ImageDropAndPaste from 'quill-image-drop-and-paste'
	import defaultToolbar from './default-toolbar'
	import ImageResize from 'quill-image-resize-module--fix-imports-error'
	import MarkdownShortcuts from './markdown-shortcuts'
	import mergeDeep from './merge-deep'
	import oldApi from './old-api'
	import htmlEditButton from './htmlEditButton'

	export default {
		name: 'VueEditor',
		emits: [
			'ready',
			'editor-change',
			'focus',
			'selection-change',
			'text-change',
			'blur',
			'input',
			'image-removed',
			'image-added',
			'image-pasted',
			'update:modelValue',
		],
		mixins: [oldApi],
		props: {
			id: {
				type: String,
				default: 'quill-container',
			},
			placeholder: {
				type: String,
				default: '',
			},
			modelValue: {
				type: String,
				default: '',
			},
			disabled: {
				type: Boolean,
			},
			editorToolbar: {
				type: [Array, Object],
			},
			editorOptions: {
				type: Object,
				required: false,
				default: () => ({}),
			},
			useCustomImageHandler: {
				type: Boolean,
				default: false,
			},
			useMarkdownShortcuts: {
				type: Boolean,
				default: false,
			},
			height: {
				type: String,
				default: '200px',
			},
			width: {
				type: String,
				default: '100%',
			},
			htmlBtn: {
				type: Boolean,
				default: false,
			},
		},

		data: () => ({
			quill: null,
			quillh: null,
			rnd: parseInt(Math.random() * 10000000),
			htmlMode: false,
			quillHtml: '',
		}),

		watch: {
			modelValue(val) {
				if (val != this.quill.root.innerHTML && !this.quill.hasFocus()) {
					this.quill.root.innerHTML = val
				}
			},
			disabled(status) {
				this.quill.enable(!status)
			},
		},

		mounted() {
			this.registerCustomModules(Quill)
			this.registerPrototypes()
			this.initializeEditor()
		},

		beforeUnmount() {
			this.quill = null
			delete this.quill
		},

		methods: {
			initializeEditor() {
				this.setupQuillEditor()
				this.checkForCustomImageHandler()
				this.handleInitialContent()
				this.registerEditorEventListeners()
				this.$emit('ready', this.quill)
			},
			setupQuillEditor() {
				let editorConfig = {
					debug: false,
					modules: this.setModules(),
					theme: 'snow',
					placeholder: this.placeholder ? this.placeholder : '',
					readOnly: this.disabled ? this.disabled : false,
				}

				this.prepareEditorConfig(editorConfig)
				this.quill = new Quill(
					this.$refs['quillContainer' + this.rnd],
					editorConfig
				)
			},

			setModules() {
				let modules = {
					toolbar: this.editorToolbar ? this.editorToolbar : defaultToolbar,
					ImageDropAndPaste: {
						handler: this.imageDropPasteHandler,
					},
					ImageResize: {
						modules: ['Resize', 'DisplaySize'],
					},
				}
				if (this.htmlBtn) {
					modules.htmlEditButton = {
						msg: 'HTML düzenlemelerinizi yaptıktan sonra kaydediniz.',
						okText: 'Kaydet',
						cancelText: 'Vazgeç',
					}
					Quill.register('modules/htmlEditButton', htmlEditButton)
				}
				Quill.register('modules/ImageDropAndPaste', ImageDropAndPaste)
				Quill.register('modules/ImageResize', ImageResize)
				if (this.useMarkdownShortcuts) {
					Quill.register('modules/markdownShortcuts', MarkdownShortcuts, true)
					modules['markdownShortcuts'] = {}
				}
				return modules
			},

			prepareEditorConfig(editorConfig) {
				if (
					Object.keys(this.editorOptions).length > 0 &&
					this.editorOptions.constructor === Object
				) {
					if (
						this.editorOptions.modules &&
						typeof this.editorOptions.modules.toolbar !== 'undefined'
					) {
						delete editorConfig.modules.toolbar
					}

					mergeDeep(editorConfig, this.editorOptions)
				}
			},
			handleInitialContent() {
				if (this.modelValue) this.quill.root.innerHTML = this.modelValue // Set initial editor content
			},
			registerPrototypes() {
				Quill.prototype.getHTML = function () {
					return this.container.querySelector('.ql-editor').innerHTML
				}
				Quill.prototype.getWordCount = function () {
					return this.container.querySelector('.ql-editor').innerText.length
				}
			},
			registerEditorEventListeners() {
				window.addEventListener('paste', this.pasteHandler)
				this.quill.on('text-change', this.handleTextChange)
				this.quill.on('selection-change', this.handleSelectionChange)
				this.listenForEditorEvent('text-change')
				this.listenForEditorEvent('selection-change')
				this.listenForEditorEvent('editor-change')
				this.listenForEditorEvent('editor-paste')
			},

			listenForEditorEvent(type) {
				this.quill.on(type, (...args) => {
					this.$emit(type, ...args)
				})
			},
			handleSelectionChange(range, oldRange) {
				if (!range && oldRange) this.$emit('blur', this.quill)
				else if (range && !oldRange) this.$emit('focus', this.quill)
			},

			handleTextChange(delta, oldContents) {
				let editorContent =
					this.quill.getHTML() === '<p><br></p>' ? '' : this.quill.getHTML()
				this.$emit('update:modelValue', editorContent)

				if (this.useCustomImageHandler)
					this.handleImageRemoved(delta, oldContents)
			},

			handleImageRemoved(delta, oldContents) {
				const currrentContents = this.quill.getContents()
				const deletedContents = currrentContents.diff(oldContents)
				const operations = deletedContents.ops
				operations.map(operation => {
					// eslint-disable-next-line no-prototype-builtins
					if (operation.insert && operation.insert.hasOwnProperty('image')) {
						const { image } = operation.insert
						this.$emit('image-removed', image)
					}
				})
			},
			checkForCustomImageHandler() {
				this.useCustomImageHandler === true ? this.setupCustomImageHandler() : ''
			},

			setupCustomImageHandler() {
				let toolbar = this.quill.getModule('toolbar')
				toolbar.addHandler('image', this.customImageHandler)
			},

			customImageHandler() {
				this.$refs.fileInput.click()
			},
			emitImageInfo($event) {
				const resetUploader = function () {
					var uploader = document.getElementById('file-upload')
					uploader.value = ''
				}
				let file = $event.target.files[0]
				let Editor = this.quill
				let range = Editor.getSelection()
				let cursorLocation = range.index
				this.$emit('image-added', file, Editor, cursorLocation, resetUploader)
			},
			imageDropPasteHandler(dataUrl, type, imageData) {
				let Editor = this.quill
				let range = (Editor.getSelection() || {}).index || Editor.getLength()
				this.$emit(
					'image-pasted',
					dataUrl,
					Editor,
					range.index + 1,
					type,
					imageData
				)
			},
		},
	}
</script>
<style src="quill/dist/quill.snow.css"></style>
<style src="./assets/vue3-editor.css"></style>
