<template>
	<r-input
		v-if="inType === 'input-text' && showMe"
		v-model="dataval"
		:label="label"
		:isdisabled="isdisabled"
		:opt="opt"
		:pos="inpos"
		:disabled="opt.edit === false"
		v-model:valid="isValid"
	/>
	<r-checkbox
		v-if="inType === 'checkbox' && showMe"
		v-model="dataval"
		:label="label"
		:isdisabled="isdisabled"
		:opt="opt"
		:pos="inpos"
		:disabled="opt.edit === false"
		v-model:valid="isValid"
	/>
	<r-textarea
		v-if="inType === 'textarea' && showMe"
		v-model="dataval"
		:label="label"
		:isdisabled="isdisabled"
		:opt="opt"
		:pos="inpos"
		:disabled="opt.edit === false"
		v-model:valid="isValid"
	/>
	<r-select
		v-if="inType === 'select' && showMe"
		v-model="dataval"
		:label="label"
		:isdisabled="isdisabled"
		:opt="opt"
		:data="opt.select.data"
		:pos="inpos"
		:txt="opt.select.txt"
		:val="opt.select.val"
		:disabled="opt.edit === false"
		v-model:valid="isValid"
	/>
</template>
<script>
	import { computed, ref, watch } from 'vue'
	import RInput from '../RInput/RInput.vue'
	import RCheckbox from '../RCheckbox/RCheckbox.vue'
	export default {
		components: {
			RInput,
			RCheckbox,
		},
		props: {
			modelValue: [Number, String, Boolean],
			opt: Object,
			label: String,
			isdisabled: Boolean,
			pos: String,
			valid: Boolean,
		},
		setup(props, { emit }) {
			const dataval = ref(props.modelValue)
			const isValid = ref(false)
			const inType = computed(() => {
				return props.opt.type || 'input-text'
			})
			const inpos = computed(() => {
				return props.pos || 'list'
			})
			const showMe = computed(() => {
				if (props.opt.hide) {
					if (typeof props.opt.hide === 'function') return props.opt.hide()
					else return !props.opt.hide
				} else return true
			})
			watch(
				() => dataval.value,
				() => {
					emit('update:modelValue', dataval.value)
				}
			)
			watch(
				() => isValid.value,
				() => {
					emit('update:valid', isValid.value)
				}
			)
			return {
				inType,
				dataval,
				inpos,
				isValid,
				showMe,
			}
		},
	}
</script>
