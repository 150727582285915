<template>
	<nav
		id="nav-top"
		:class="['nav', store.state.isScrolled ? 'top-nav-fixed' : '']"
	>
		<r-flex class="r-nav-top-top-C">
			<r-flex flx="8" align="space-between center" class="r-flx-tw-100 r-ph-16">
				<div>
					<strong>TUSDATA YÖNETİM PANELİ</strong>
					| {{ supporttxt }}
				</div>
				<div>
					<strong v-if="store.state.supers.indexOf(store.state.userrole) >= 0">
						Versiyon:1.2.9 | Çalışma Klasörü: {{ store.state.workFolder }} |
					</strong>
					<strong>Hoşgeldin, {{ store.state.username }}</strong>
					|
					{{ mxDate('dd-mm-yy-dddd', new Date(), '.') }}
				</div>
			</r-flex>
		</r-flex>
		<r-flex flx="8" class="nav-top-links r-flx-nop r-flx-tw-100 r-ph-16">
			<r-flex class="r-flx-nop" align="flex-start center">
				<img :src="logos" width="100" />
				<nav-item :links="linkssorular" />
				<nav-item :links="linksreferanslar" />
			</r-flex>
			<r-flex flx="2">
				<r-select
					v-model="store.state.usertype"
					label="Bölüm"
					:data="typeList"
					txt="title"
					val="id"
					@change="changeType"
					v-if="store.state.supers.indexOf(store.state.userrole) >= 0"
				/>
			</r-flex>
			<span class="r-check-no log-out-btn" @click="logOut"></span>
		</r-flex>
	</nav>
</template>
<script>
	import * as Cookies from 'js-cookie'
	import router from '@/router/'
	import { useStore } from 'vuex'
	import { computed, onBeforeMount, ref } from 'vue'
	import NavItem from './NavItem'
	import { mxDate } from '@/mix/global'
	export default {
		components: {
			NavItem,
		},
		setup() {
			const store = useStore()
			const logos = computed(() => {
				if (store.state.usertype === 0)
					return require('@/assets/img/logo-tusdata-s.png')
				else if (store.state.usertype === 1)
					return require('@/assets/img/logo-dusdata-s.png')
				else if (store.state.usertype === 2)
					return require('@/assets/img/logo-ydusdata-s.png')
			})
			const supporttxt = ref('')
			const showMe = d => {
				return [0].some(r => d.id.includes(r))
			}
			const typeList = [
				{
					title: 'TUS',
					id: 0,
				},
				{
					title: 'DUS',
					id: 1,
				},
				{
					title: 'YDUS',
					id: 2,
				},
			]
			const linkssorular = ref([
				{
					route: '/hesap-yonetimi',
					txt: 'Hesap Yönetimi',
					svg: require('@/assets/img/ico-hesap-yonetim.svg'),
					id: [0],
				},
				{
					route: '/sorular/liste',
					txt: 'Soru Listesi',
					svg: require('@/assets/img/ico-sorular.svg'),
					id: [1],
				},
				{
					route: '/sorular/kategoriler',
					txt: 'Kategoriler',
					svg: require('@/assets/img/ico-kategoriler.svg'),
					id: [2],
				},
			])

			const linksreferanslar = ref([
				{
					route: '/referanslar/liste',
					txt: 'Referans Listesi',
					svg: require('@/assets/img/ico-referanslar.svg'),
				},
				{
					route: '/ders-notlari',
					txt: 'Ders Notları',
					svg: require('@/assets/img/ico-ders-notlari.svg'),
					id: [4],
				},
				{
					route: '/ders-notu-ata',
					txt: 'Ders Notu Ata',
					svg: require('@/assets/img/ico-ders-notu-ata.svg'),
					id: [5],
				},
				{
					route: '/loglar',
					txt: 'Loglar',
					svg: require('@/assets/img/ico-loglar.svg'),
					id: [6],
				},
				{
					route: '/export',
					txt: 'Export',
					svg: require('@/assets/img/ico-export.svg'),
					id: [8],
				},
			])
			const changeType = e => {
				store.state.usertype = e.id
				let t = JSON.parse(Cookies.get('tdutype'))
				if (t) {
					t.ut = e.id
					Cookies.set('tdutype', JSON.stringify(t))
					location.reload()
				} else {
					router.push('/giris')
				}
			}
			const getSettings = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'settings/getsettingsforuser',
				})
				return list
			}
			const openSettings = async () => {
				let data = await getSettings()
				data.data.forEach(o => {
					if (o.key === 'support') supporttxt.value = o.value
				})
			}
			const logOut = () => {
				if (store.state.usertype === 0) Cookies.remove('tdataref')
				if (store.state.usertype === 1) Cookies.remove('ddataref')
				if (store.state.usertype === 2) Cookies.remove('yddataref')
				store.state.selectedcatid = null
				router.push('/giris')
			}
			onBeforeMount(async () => {
				await openSettings()
			})

			return {
				logOut,
				linkssorular,
				linksreferanslar,
				mxDate,
				store,
				changeType,
				typeList,
				logos,
				showMe,
				supporttxt,
			}
		},
	}
</script>
<style>
	.nav-top-links {
		flex-wrap: nowrap !important;
		flex: 0 0 auto !important;
		align-items: center !important;
	}
	.top-nav-fixed {
		position: fixed;
		top: -100px !important;
	}
	.top-nav-fixed .log-out-btn {
		align-self: flex-end;
		background-color: transparent;
		margin-bottom: 4px;
	}
	.top-nav-fixed .log-out-btn:before,
	.top-nav-fixed .log-out-btn:after {
		border-top: 2px solid #9c0000;
	}
	.r-nav-top-top-C {
		background-color: #eaeaea;
		padding: 8px;
		justify-content: center !important;
		align-items: center !important;
	}
</style>
