/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import RootPage from '@/RootPage'
import * as Cookies from 'js-cookie'

const routes = [
	{
		path: '/',
		name: 'RootPage',
		component: RootPage,
		children: [
			{
				path: '/dashboard',
				name: 'dashboard',
				component: () =>
					import(/* webpackChunkName: "dashboard" */ '@/Pages/Dashboard.vue'),
			},
		],
	},
	{
		path: '/giris',
		name: 'login',
		component: () =>
			import(/* webpackChunkName: "login" */ '@/Pages/Login/Login.vue'),
	},
	{
		path: '/hesap-yonetimi',
		name: 'hesapyonetimi',
		component: RootPage,
		children: [
			{
				path: '',
				name: 'hesapyonetimiana',
				meta: { id: 1 },
				component: () =>
					import(
						/* webpackChunkName: "hesapyonetimi" */ '@/Pages/Ayarlar/PageKullanicilar.vue'
					),
			},
		],
	},
	{
		path: '/sorular',
		name: 'sorular',
		component: RootPage,
		children: [
			{
				path: 'liste',
				name: 'soruliste',
				meta: { id: 2 },
				component: () =>
					import(
						/* webpackChunkName: "sorular" */ '@/Pages/Sorular/PageSorular.vue'
					),
			},
			{
				path: 'kategoriler',
				name: 'sorukategori',
				meta: { id: 3 },
				component: () =>
					import(
						/* webpackChunkName: "sorular" */ '@/Pages/Sorular/PageKategoriler.vue'
					),
			},
		],
	},
	{
		path: '/referanslar',
		name: 'referanslar',
		component: RootPage,
		children: [
			{
				path: 'liste',
				name: 'referansliste',
				meta: { id: 4 },
				component: () =>
					import(
						/* webpackChunkName: "referansliste" */ '@/Pages/Referanslar/PageReferanslar.vue'
					),
			},
			{
				path: 'soru/:id',
				name: 'sorureferanslari',
				meta: { id: 4 },
				component: () =>
					import(
						/* webpackChunkName: "referansliste" */ '@/Pages/Referanslar/PageSoruReferans.vue'
					),
			},
			{
				path: 'ekle/:id/:nid',
				name: 'referansekle',
				meta: { id: 4 },
				component: () =>
					import(
						/* webpackChunkName: "referansliste" */ '@/Pages/Referanslar/PageReferansEkle.vue'
					),
			},
			{
				path: 'duzenle/:id',
				//path: 'duzenle/:id/:nid',
				name: 'referansduzenle',
				meta: { id: 4 },
				component: () =>
					import(
						/* webpackChunkName: "referansliste" */ '@/Pages/Referanslar/PageReferansEkle.vue'
					),
			},
		],
	},
	{
		path: '/ders-notlari',
		name: 'dersnotlari',
		component: RootPage,
		meta: { id: 5 },
		children: [
			{
				path: '',
				name: 'dersnotlariliste',
				meta: { id: 5 },
				component: () =>
					import(
						/* webpackChunkName: "dersnotlari" */ '@/Pages/Referanslar/PageDersNotlari.vue'
					),
			},
		],
	},
	{
		path: '/ders-notu-ata',
		name: 'dersnotuata',
		component: RootPage,
		meta: { id: 6 },
		children: [
			{
				path: '/ders-notu-ata',
				name: 'dersnotuatalist',
				meta: { id: 6 },
				component: () =>
					import(
						/* webpackChunkName: "dersnotuata" */ '@/Pages/Referanslar/PageDersNotuAta.vue'
					),
			},
		],
	},
	{
		path: '/loglar',
		name: 'loglar',
		component: RootPage,
		meta: { id: 7 },
		children: [
			{
				path: '',
				name: 'loglarlist',
				meta: { id: 7 },
				component: () =>
					import(
						/* webpackChunkName: "loglar" */ '@/Pages/Referanslar/PageLoglar.vue'
					),
			},
		],
	},
	{
		path: '/export',
		name: 'export',
		component: RootPage,
		meta: { id: 8 },
		children: [
			{
				path: '',
				name: 'exportlist',
				meta: { id: 8 },
				component: () =>
					import(
						/* webpackChunkName: "export" */ '@/Pages/Export/PageExport.vue'
					),
			},
		],
	},
	{
		path: '/yorumlar',
		name: 'yorumlar',
		component: RootPage,
		meta: { id: 7 },
		children: [
			{
				path: '',
				name: 'yorumlarlist',
				meta: { id: 7 },
				component: () =>
					import(
						/* webpackChunkName: "yorumlar" */ '@/Pages/Referanslar/PageYorumlar.vue'
					),
			},
		],
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { top: 0, 'scroll-behavior': 'smooth' }
	},
})
router.beforeEach((to, from, next) => {
	next()
})
export default router
